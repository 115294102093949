function setEqualHeight() {

    var current_breakpoint_num = getNumSkelSize();
    if (current_breakpoint_num > 2) {
        $(".grid-menu-item-022-top .menu-item-row-element").matchHeight({
            byRow: false
        });
        $(".grid-menu-item-022-bottom .menu-item-row-element").matchHeight({
            byRow: false
        });
    }

    $(".grid-menu-item-012-001, .grid-menu-item-020").matchHeight();

    /*
    if (current_breakpoint_num < 3) {
        $(".grid-menu-item-022-left .menu-item-row-element").matchHeight({
            byRow: false
        });
        $(".grid-menu-item-022-right .menu-item-row-element").matchHeight({
            byRow: false
        });
    }
    */
    // $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".news-image-wrapper, .news-content-wrapper ").matchHeight();
    $(".equal-height").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
    setTimeout(
        function() {
            $.fn.matchHeight._update();
        },
        500
    );
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 40,
        scroll_limit = 161,
        initial_height = 120,
        target_height = 80,
        initial_padding = 28,
        target_padding = 10,
        initial_margin_top = 0,
        target_margin_top = -40,
        margin,
        logo_height,
        padding,
        line_height;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit_1 = 40;
            scroll_limit = 161;
            initial_height = 120;
            target_height = 60;
            initial_padding = 28;
            target_padding = 8;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "xxlarge":
            scroll_limit_1 = 40;
            scroll_limit = 161;
            initial_height = 120;
            target_height = 60;
            initial_padding = 28;
            target_padding = 8;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "large":
            scroll_limit_1 = 40;
            scroll_limit = 121;
            initial_height = 80;
            target_height = 60;
            initial_padding = 18;
            target_padding = 8;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "medium":
            scroll_limit_1 = 30;
            scroll_limit = 101;
            initial_height = 70;
            target_height = 60;
            initial_padding = 13;
            target_padding = 8;
            initial_margin_top = 0;
            target_margin_top = -30;
            break;
        case "small":
            scroll_limit_1 = 30;
            scroll_limit = 101;
            initial_height = 70;
            target_height = 60;
            initial_padding = 13;
            target_padding = 8;
            initial_margin_top = 0;
            target_margin_top = -30;
            break;
        case "xsmall":
            scroll_limit_1 = 30;
            scroll_limit = 91;
            initial_height = 60;
            target_height = 60;
            initial_padding = 15;
            target_padding = 15;
            initial_margin_top = 0;
            target_margin_top = -30;
            break;
        default:
            scroll_limit_1 = 40;
            scroll_limit = 161;
            initial_height = 120;
            target_height = 80;
            initial_padding = 28;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
            margin_top = initial_margin_top
            padding = initial_padding;
            logo_height = initial_height;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
            margin_top = initial_margin_top - $(window).scrollTop();
            padding = initial_padding;
            logo_height = initial_height;
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            padding = initial_padding * ((scroll_limit - scroll_limit_1) - ($(window).scrollTop() - scroll_limit_1)) / (scroll_limit - scroll_limit_1);
            logo_height = initial_height - (initial_height - target_height) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").show();
                $("#logo-scroll").hide();
            }
            */
        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            padding = target_padding;
            logo_height = target_height;
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").hide();
                $("#logo-scroll").show();
            }
            */
        }
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo a").css("height", logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");

        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
        }
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});

$('#main-menu-state-left, #main-menu-state').on("change", function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($menu.offset().top - $(window).scrollTop()));
                $menu.css("max-height", maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height", "");
            $menu.css("height", "");
        });
    }
});

$(function() {
    $("#main-menu-left").smartmenus({
        mainMenuSubOffsetX: 1,
        mainMenuSubOffsetY: -8,
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
        keepInViewport: true,
        keepHighlighted: true,
        markCurrentItem: true,
        markCurrentTree: true
        /*}).bind("click.smapi", function(e, item) {
            var $item = $(item);
            if($item.attr("href") === "#") {
                e.preventDefault();
                $('#main-menu').smartmenus('itemActivate', $item);
                return false;
            }*/
    });

    $("#moj-racun-odjava").on("click", function(){
        login.logout();
        return false;
    });
});

/*
$('#sidebar').stickySidebar({
    topSpacing: 60,
   bottomSpacing: 60
});
*/
var arslunaStickySidebar = function($) {
    "use strict";
    var sidebar, created = false;

    function init() {
        var screen = getNumSkelSize();
        if (screen <= 3) {
            $("#main-left, #main-center").matchHeight({
                remove: true
            });
            if (created) {
                arslunaStickySidebar.sidebar.destroy();
            }
            return false;
        } else {
            $("#main-left, #main-center").matchHeight();
        }
        if (created) {
            update();
        } else {
            $("#main-left, #main-center").matchHeight();
            arslunaStickySidebar.sidebar = new StickySidebar('#sidebar', {
                topSpacing: topSpacingGet(0),
                bottomSpacing: 20,
                containerSelector: '#main-left',
                innerWrapperSelector: '.page-left-column-content'
            });
            created = true;
        }
    } // END init

    function update() {
        var screen = getNumSkelSize();
        if (screen <= 3) {
            $("#main-left, #main-center").matchHeight({
                remove: true
            });
            destroy();
        } else {
            if (created) {
                $.fn.matchHeight._update();
                arslunaStickySidebar.sidebar.updateSticky();
            } else {
                init();
            }
        }
    } // END update;

    function updateTimeouted() {
        if ($("#main-left").length > 0 && $("#sidebar").length) {
            window.setTimeout(arslunaStickySidebar.update, 30);
        }
    } // END updateDelay

    function destroy() {
        try {
            if (created) {
                arslunaStickySidebar.sidebar.destroy();
            }
            $("#sidebar").removeClass("is-affixed").removeAttr("style");
            $("#sidebar .sidebar__inner").removeAttr("style");
        } catch (e) {}
    } // END destroy;

    function topSpacingGet() {
        var spacing = $("#sidebar").offset().top;
        return spacing;
    } // END stickySidebarTopSpacingGet

    function bottomSpacingGet() {
        var spacing = $("#footer-wrapper").outerHeight();
        return spacing;
    } // END stickySidebarBottomSpacingGet

    return {
        init: init,
        update: update,
        updateTimeouted: updateTimeouted,
        destroy: destroy
    }

}(jQuery); // END arslunaStickySidebar

$(window).on("load", function() {
    if ($("#main-left").length > 0 && $("#sidebar").length) {
        arslunaStickySidebar.init();
    }
});

$(window).on("load", arslunaStickySidebar.updateTimeouted);
$(window).on("resize", arslunaStickySidebar.updateTimeouted);
$(window).on("orientationchange", arslunaStickySidebar.updateTimeouted);

$("#filter-trademark-select").on('change.select2', function (e) {
    $.fn.matchHeight._update();
});
